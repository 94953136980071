<template>
    <div class="app-container">
        <el-form :size="$store.getters.size" :inline="true" :model="queryParams" ref="queryParams">
            <el-form-item>
                <el-select filterable placeholder="选择仓库" v-model="queryParams.warId" clearable >
                    <el-option v-for="(item, index) in warehouseOption" :label="item.label" :key="index" :value="item.value" />
                </el-select>
            </el-form-item>
            <el-form-item>
                <el-date-picker
                        v-model="queryParams.time"
                        type="month"
                        :format="format"
                        value-format="yyyy-MM-dd"
                        :picker-options="pickerOptions"
                        placeholder="选择日期">
                </el-date-picker>
            </el-form-item>
            <el-form-item v-if="queryParams.type === 'month'">
                <el-select v-model="queryParams.driverId" placeholder="选择司机" clearable filterable>
                    <el-option v-for="(item, index) in driverData" :label="item.driverName" :value="item.guid" :key="index"></el-option>
                </el-select>
            </el-form-item>
            <el-form-item>
                <el-button type="primary" @click="querySearch" :loading="queryLoading">查询</el-button>
            </el-form-item>
        </el-form>
        <el-row :gutter="1.5">
            <el-col :span="24">
                <el-button :size="$store.getters.size" type="primary" icon="el-icon-download"
                           plain @click="exportExcelInvoice" v-if="$app.haveRole('delivery:Invoice:export')">运费导出</el-button>
            </el-col>
        </el-row>

        <el-table style="margin-top: 20px" :data="tableData"  ref="table" :summary-method="summaryMethod" show-summary border>
            <el-table-column label="商户名称" align="center" prop="invoiceName" width="250"></el-table-column>
            <el-table-column label="日期" align="center">
                <el-table-column v-for="item in 31" :label="item.toString()" prop=""  :key="item" align="center" >
                    <template slot-scope="scope">
                        {{ scope.row.freightList[item - 1]}}
                    </template>
                </el-table-column>
            </el-table-column>
            <el-table-column label="合计" align="center" width="120">
                <template slot-scope="scope">
                    {{moneyTotal(scope.row)}}
                </template>
            </el-table-column>
        </el-table>

      <!--  <div id="tableContent" style="width: 100%;">
        <freight-month ref="freight" v-if="queryParams.type === 'month'"/>
    </div>-->

    </div>
</template>

<script>
    // 商户运费
    import {getAllDriver} from '@/api/system/driver'
    import {exportExcelInvoice, getInvoiceMonthDriver} from '@/api/delivery/freight'

    export default {
        name: "invoiceMonthDriver",
        data(){
            return{
                queryParams: {
                    warId: undefined,
                    type: 'month',
                    time: undefined,
                    driverId: undefined,
                    driverName: undefined,
                },
                format: 'yyyy-MM',
                warehouseOption: this.$store.getters.warehouse,
                driverData: [],
                queryLoading: false,
                tableData: [],
                pickerOptions: {
                    disabledDate(time) {
                        return time.getTime() > Date.now() - 8.64e6;  //如果没有后面的-8.64e6就是不可以选择今天的
                    }
                },
                rowData: []
            }
        },
        methods: {
            querySearch(){
                if (!this.queryParams.warId){
                    this.$message.warning("请选择仓库");
                    return;
                }
                if (!this.queryParams.time){
                    this.$message.warning("请选择查询日期");
                    return;
                }
                if (!this.queryParams.driverId){
                    this.$message.warning("请选择司机");
                    return;
                }
                if(this.queryLoading){return;}
                this.queryLoading = true;
                getInvoiceMonthDriver(this.queryParams).then(({code, data}) => {
                    this.$message.success("操作完成")
                    this.queryLoading = false
                    this.tableData = data
                })
            },
            exportExcelInvoice(){
                exportExcelInvoice(this.queryParams).then(({code}) => {})
            },

            moneyTotal({freightList}){
                let result = 0;
                freightList.forEach(function(arr) {
                    result += arr;
                });
                return result;
            },
            /** 自定义合计 */
            summaryMethod({ columns, data }){
                let sums = [];
                columns.forEach((column, index) => {
                    if (index === 0) {
                        sums[index] = '总计';
                    }else if(index === 32){
                        let count = 0;
                        data.forEach(e => {
                            count += this.moneyTotal(e);
                        })
                        sums[index] = count;
                    }
                })
                return sums;
            },

        },
        watch: {
            'queryParams.type': function (val) {
                this.rowData = []
                if(val === 'date'){
                    this.format = 'yyyy-MM-dd'
                }else{
                    this.format = 'yyyy-MM'
                }
            },
            'queryParams.warId': function (val) {
                this.driverData = [];
                this.queryParams.driverId = undefined
                if(val) {
                    getAllDriver({warId: val}).then(({code, data}) => {
                        if (code === 0) {
                            this.driverData = data;
                        }
                    })
                    const data = this.warehouseOption.filter(item => item.value == val)
                    this.queryParams.warName = data[0].label
                }
            },
            'queryParams.driverId': function (val) {
                if(val){
                    const data = this.driverData.filter(item => item.guid == val)
                    this.queryParams.driverName = data[0].driverName
                }
            }
        }
    }
</script>

<style scoped>

</style>